.container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
}
.container__header .contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.container__header .contact a {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  font-size: 0.86rem;
}
.socials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 1rem;
}
.socials a {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .contact a span {
    display: none;
  }
}
